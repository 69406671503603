<template>
  <small class="login-footer">
    All access to and use of this App subject to Privacy Statement and governed
    by End User License Agreement and Terms of Service.
  </small>
</template>

<script>
export default {
  name: "LoginFooter",
  props: {
    msg: String,
  },
};
</script>

<style scoped lang="scss">
.login-footer {
  width: 85%;
  font-family: var(--sans);
  font-size: 0.55rem;
  text-align: center;
  margin-top: 30px;
  padding-bottom: 10px;
}
</style>
